import { navigate } from "gatsby";
import { useLocalization } from "../hooks";
import { useCallback } from "react";

const useNavigate = () => {
  const { locale, defaultLang } = useLocalization();

  const localizedNavigate = useCallback(
    (path, options) => {
      return navigate(
        `${locale === defaultLang ? `` : `/${locale}`}${path}`,
        options || {}
      );
    },
    [locale, defaultLang]
  );

  return localizedNavigate;
};

export default useNavigate;
