import React from "react";
import { CartContext, CartContextProvider } from "./CartContext";
import { LocationsContext, LocationsContextProvider } from "./locationsContext";
import { UserContextProvider, UserAuthContext } from "./userAuth";
import { CustomerContextProvider, CustomerContext } from "./customerContext";
import { SearchContextProvider, SearchContext } from "./searchContext";
import { MaintenanceContextProvider, MaintenanceContext } from "./maintenanceContext";
import { GoogleMapsContextPorvider, GoogleMapsContext } from "./googleMapsContext";

import { BreakpointContext } from "./breakpointContext";
import { ModalContextProvider, ModalContext } from "./modalContext";

import { ApolloProvider } from "@apollo/client";
import client from "../utils/apolloClient";

import { RestaurantContextProvider, RestaurantContext } from "./restaurantContext";

const ContextProviders = ({ children }) => {
  return (
    <UserContextProvider>
      <ApolloProvider client={client}>
        <ModalContextProvider>
          <RestaurantContextProvider>
            <MaintenanceContextProvider>
              <CustomerContextProvider>
                <LocationsContextProvider>
                  <CartContextProvider>
                    <GoogleMapsContextPorvider>
                      <SearchContextProvider>{children}</SearchContextProvider>
                    </GoogleMapsContextPorvider>
                  </CartContextProvider>
                </LocationsContextProvider>
              </CustomerContextProvider>
            </MaintenanceContextProvider>
          </RestaurantContextProvider>
        </ModalContextProvider>
      </ApolloProvider>
    </UserContextProvider>
  );
};
export default ContextProviders;
export { CartContext, LocationsContext, UserAuthContext, CustomerContext, SearchContext, BreakpointContext, MaintenanceContext, ModalContext, RestaurantContext, GoogleMapsContext };
