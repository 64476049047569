import { useEffect, useState, useContext } from "react";
import _ from "lodash";

import { useUpdateCart } from "../mutations";
import { CartContext, LocationsContext, CustomerContext } from "../context";
import useUpdateCartContext from "./useUpdateCartContext";

const useUpdateCartHook = () => {
  const [updated, setUpdated] = useState(false);
  const { selectedLocation } = useContext(LocationsContext);
  const { customer } = useContext(CustomerContext);
  const { onUpdate: updateCartContext } = useUpdateCartContext();

  const {
    data: dataUpdateCart,
    error: errorUpdateCart,
    setData: setUpdateCartVariable,
  } = useUpdateCart();

  const {
    cartId,
    cartToken,
    cartLineItems,
    cartNote,
    cartEmail,
    shippingAddress,
    billingAddress,
    cartPhone,
    orderType,
    scheduleTime,
    cartTip,
    cartOffer,
  } = useContext(CartContext);

  const onUpdate = (state) => {
    setUpdated(false);
    if (_.isEmpty(cartId) || _.isEmpty(cartToken)) {
      setUpdated(true);
      return;
    }
    const addressKeys = [
      "address_1",
      "address_2",
      "city",
      "province",
      "postalCode",
      "country",
      "firstName",
      "lastName",
      "phone",
      "countryCode",
      "lat",
      "long",
    ];
    let variables = {
      id: cartId,
      token: cartToken,
      location: selectedLocation.id,
      restaurant: process.env.GATSBY_STRAPI_RESTAURANT_ID,
      state: state,
    };

    if (!_.isEmpty(customer)) variables.user = customer.id;
    if (!_.isEmpty(cartEmail)) variables.email = cartEmail;
    if (!_.isEmpty(cartPhone)) variables.phone = cartPhone;

    if (_.isEmpty(variables.email) && !_.isEmpty(customer))
      variables.email = customer.email;

    if (_.isEmpty(variables.phone) && !_.isEmpty(customer))
      variables.phone = customer.phone;

    if (!_.isEmpty(cartLineItems))
      variables.lineitems = _.map(cartLineItems, (v, k) => {
        let lineItem = {
          item: v.item.id,
          quantity: v.quantity,
          note: v.note,
          title: v.title,
        };
        if (!_.isEmpty(v.choices)) {
          lineItem.choices = v.choices.map((c) => {
            return {
              modifier: c.modifier.id,
              quantity: c.quantity,
              item: c.item.id,
            };
          });
        }
        return lineItem;
      });

    if (!_.isEmpty(shippingAddress)) {
      variables.shippingAddress = _.pick(shippingAddress, addressKeys);
      if (_.isEmpty(variables.shippingAddress.phone) && cartPhone)
        variables.shippingAddress.phone = cartPhone;
    }

    if (!_.isEmpty(billingAddress)) {
      variables.billingAddress = _.pick(billingAddress, addressKeys);
      if (_.isEmpty(variables.billingAddress.phone) && cartPhone)
        variables.billingAddress.phone = cartPhone;
    }

    if (!_.isEmpty(orderType)) variables.orderType = orderType;
    if (!_.isEmpty(cartNote)) variables.note = cartNote;
    if (!_.isEmpty(scheduleTime))
      variables.scheduleTime = _.pick(scheduleTime, ["display", "to", "from"]);

    if (cartTip > 0) variables.tip = cartTip;
    if (!_.isEmpty(cartOffer)) variables.offer = cartOffer.id;

    console.log("variables", variables);
    setUpdateCartVariable({ variables });
  };

  useEffect(() => {
    if (!_.isEmpty(errorUpdateCart)) {
      console.log(JSON.stringify(errorUpdateCart));
      setUpdated(true);
      return;
    }
    if (_.isEmpty(dataUpdateCart?.updateCart.cart)) {
      setUpdated(true);
      return;
    }
    updateCartContext(dataUpdateCart.updateCart.cart);

    setUpdated(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUpdateCart, errorUpdateCart]);

  return { updated, onUpdate };
};

export default useUpdateCartHook;
