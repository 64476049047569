import { useState } from "react";
const isBrowser = () => typeof window !== "undefined";

function useSessionStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      if (isBrowser() && window.sessionStorage) {
        // Get from local storage by key
        const item = window.sessionStorage.getItem(key);
        // Parse stored json or if none return initialValue
        return item ? JSON.parse(item) : initialValue;
      }
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to sessionStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      if (isBrowser() && window.sessionStorage)
        // Save to local storage
        window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  const removeValue = () => {
    try {
      // Allow value to be a function so we have same API as useState
      // Save state
      setStoredValue(null);
      // Save to local storage
      if (isBrowser() && window.sessionStorage)
        window.sessionStorage.removeItem(key);
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue, removeValue];
}
export default useSessionStorage;
