import { useMemo, useContext } from "react";
import { LocationsContext } from "../context";

const usePriceFormat = () => {
  const { selectedLocation, locations } = useContext(LocationsContext);

  const priceSymbols = useMemo(() => {
    return {
      usd: {
        symbol: "$",
        left: true,
      },
      cad: {
        symbol: "$",
        left: true,
      },
      dkk: {
        symbol: "kr",
        left: false,
      },
    };
  }, []);
  const priceSymbol = useMemo(() => {
    const currency = selectedLocation
      ? locations[0]?.currency
      : selectedLocation?.currency;
    if (currency) return priceSymbols[currency]?.symbol;
    return "";

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation]);

  const priceFormat = (price) => {
    if (!price) return price;
    const currency = selectedLocation
      ? locations[0]?.currency
      : selectedLocation?.currency || "cad";

    const cf = priceSymbols[currency];
    if (!cf) return price?.toFixed(2);
    if (cf.left) {
      return `${cf.symbol}${price?.toFixed(2)}`;
    }
    return `${price?.toFixed(2)}${cf.symbol}`;
  };

  return { priceFormat, priceSymbol };
};

export default usePriceFormat;
