import React, { useEffect, useState } from "react";
import { useSessionStorage } from "../../hooks";
import _ from "lodash";

const CartContext = React.createContext({});

const CartContextProvider = ({ children }) => {
  const [cartLineItems, setCartLineItems] = useSessionStorage(
    "cartLineItems",
    {}
  );
  const [cartEmail, setCartEmail] = useSessionStorage("cartEmail", "");
  const [cartPhone, setCartPhone] = useSessionStorage("cartPhone", "");
  const [cartNote, setCartNote] = useSessionStorage("cartNote", "");
  const [orderType, setOrderType] = useSessionStorage("orderType", "");
  const [scheduleTime, setScheduleTime] = useSessionStorage("scheduleTime", {});
  const [cartId, setCartId] = useSessionStorage("cartId", "");
  const [cartToken, setCartToken] = useSessionStorage("cartToken", "");
  const [cartTotals, setCartTotals] = useSessionStorage("cartTotals", {
    qty: 0,
    subtotal: 0,
    quantitiesPerItem: {},
  });
  const [cartPayment, setCartPayment] = useSessionStorage("cartPayment", {});
  const [shippingAddress, setShippingAddress] = useSessionStorage(
    "cartShippingAddress",
    {}
  );
  const [billingAddress, setBillingAddress] = useSessionStorage(
    "cartBillingAddress",
    {}
  );
  const [bothAddressesAreEqual, setBothAddressesAreEqual] = useSessionStorage(
    "cartBothAddressesAreEqual",
    true
  );

  const [isCartComplete, setIsCartComplete] = useState(false);
  const [cartBeingProcessed, setCartBeingProcessed] = useState(false);
  const [canDeliver, setCanDeliver] = useSessionStorage("canDeliver", true);
  const [cartTip, setCartTip] = useSessionStorage("cartTip", 0);
  const [cartOffer, setCartOffer] = useSessionStorage("cartOffer", {});

  const clearCart = () => {
    setCartId("");
    setCartToken("");
    setCartLineItems({});
    setCartTotals({
      qty: 0,
      subtotal: 0,
      quantitiesPerItem: {},
    });
    setIsCartComplete(false);
    setOrderType("");
    setCartEmail("");
    setCartPhone("");
    setScheduleTime({});
    setCartPayment("");
    setShippingAddress({});
    setBillingAddress({});
    setBothAddressesAreEqual(true);
    setCartPayment({});
    setCartBeingProcessed(false);
    setCartNote("");
    setCanDeliver(true);
    setCartTip(0);
    setCartOffer("");
  };
  useEffect(() => {
    if (
      _.isEmpty(cartToken) ||
      _.isEmpty(orderType) ||
      _.isEmpty(cartPayment) ||
      _.isEmpty(scheduleTime) ||
      _.isEmpty(billingAddress) ||
      _.isUndefined(billingAddress.postalCode) ||
      _.isEmpty(billingAddress.firstName) ||
      _.isUndefined(billingAddress.firstName) ||
      _.isEmpty(billingAddress.firstName) ||
      _.isUndefined(billingAddress.lastName) ||
      _.isEmpty(billingAddress.lastName) ||
      _.isUndefined(billingAddress.address_1) ||
      _.isEmpty(billingAddress.address_1) ||
      _.isUndefined(cartPhone) ||
      _.isEmpty(cartPhone)
    ) {
      setIsCartComplete(false);
      return;
    }

    setIsCartComplete(true);
  }, [
    cartToken,
    orderType,
    cartPayment,
    billingAddress,
    scheduleTime,
    cartPhone,
  ]);

  return (
    <CartContext.Provider
      value={{
        cartLineItems,
        setCartLineItems,
        orderType,
        setOrderType,
        cartId,
        setCartId,
        cartToken,
        setCartToken,
        cartTotals,
        setCartTotals,
        cartEmail,
        setCartEmail,
        cartPhone,
        setCartPhone,
        isCartComplete,
        bothAddressesAreEqual,
        setBothAddressesAreEqual,
        shippingAddress,
        setShippingAddress,
        billingAddress,
        setBillingAddress,
        cartPayment,
        setCartPayment,
        cartBeingProcessed,
        setCartBeingProcessed,
        clearCart,
        cartNote,
        setCartNote,
        scheduleTime,
        setScheduleTime,
        canDeliver,
        setCanDeliver,
        cartTip,
        setCartTip,
        cartOffer,
        setCartOffer,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
export default CartContext;
export { CartContextProvider, CartContext };
