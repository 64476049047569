import React, { useEffect, useMemo, useState } from "react";
import { useLocalStorage, useSessionStorage } from "../hooks";
import _ from "lodash";
import auth from "../utils/auth";

const UserAuthContext = React.createContext({});
const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [userLocalStorage, setUserLocalStorage, removeUserLocalStorage] =
    useLocalStorage("userLocalStorageInfoAndToken", {});
  const [userSessionStorage, setUserSessionStorage, removeUserSessionStorage] =
    useSessionStorage("userSessionStorageInfoAndToken", {});

  useEffect(() => {
    if (!_.isEmpty(userLocalStorage)) {
      setUser(userLocalStorage);
      console.log("userLocalStorage", userLocalStorage);
      return;
    }
    if (!_.isEmpty(userSessionStorage)) {
      setUser(userSessionStorage);
      console.log("userSessionStorage", userSessionStorage);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSetUser = (data) => {
    if (_.isEmpty(data)) return;

    setUser(data);
    if (data.rememberMe) setUserLocalStorage(data);
    else setUserSessionStorage(data);
  };
  const removeUser = () => {
    if (user.rememberMe) removeUserLocalStorage();
    else removeUserSessionStorage();

    setUser({});
  };

  const isAuthenticated = useMemo(() => {
    return user && user.accessToken ? true : false;
  }, [user]);
  return (
    <UserAuthContext.Provider
      value={{ user, setUser: onSetUser, removeUser, isAuthenticated }}
    >
      {children}
    </UserAuthContext.Provider>
  );
};

// use it without the context or hook

const getUserInfo = () => {
  const userSessionStorageInfoAndToken = auth.get(
    "userSessionStorageInfoAndToken"
  );
  const userLocalStorageInfoAndToken = auth.get("userLocalStorageInfoAndToken");

  if (!_.isEmpty(userSessionStorageInfoAndToken))
    return userSessionStorageInfoAndToken;
  if (!_.isEmpty(userLocalStorageInfoAndToken))
    return userLocalStorageInfoAndToken;
  return {};
};

export { UserContextProvider, UserAuthContext, getUserInfo };
