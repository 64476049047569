import { useCallback, useContext } from "react";
import { CartContext } from "../context";
import _ from "lodash";
import produce from "immer";

const useUpdateCartQty = () => {
  const cartContext = useContext(CartContext);
  const getQty = useCallback(
    ({ id, quantity }) => {
      const updatedCart = produce(cartContext.cartLineItems, (draft) => {
        if (quantity < 1) delete draft[id];
        else draft[id].quantity = quantity;
      });
      cartContext.setCartLineItems(updatedCart);

      let cartTotals = { qty: 0, subtotal: 0, quantitiesPerItem: {} };
      _.forEach(updatedCart, (v, k) => {
        cartTotals.qty += v.quantity;
        cartTotals.subtotal += v.unitPrice * v.quantity;

        if (!cartTotals.quantitiesPerItem[v.item.id])
          cartTotals.quantitiesPerItem[v.item.id] = 0;
        cartTotals.quantitiesPerItem[v.item.id] += v.quantity;
      });
      cartContext.setCartTotals((c) => {
        return {
          ...c,
          ...cartTotals,
        };
      });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cartContext.cartLineItems]
  );

  return getQty;
};

export default useUpdateCartQty;
