/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "react-select2-wrapper/css/select2.css";
import "./src/styles/App.css";
import "./src/styles/icofont/icofont.css";
import "animate.css/animate.min.css";
import "@fortawesome/fontawesome-svg-core/styles.css";
import "./src/styles/phoneInput.css";

import React from "react";
import Providers from "./src/providers";

export const wrapRootElement = ({ element }) => {
  return <Providers>{element}</Providers>;
};

