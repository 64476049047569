import React, { useMemo } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

const Icofont = ({
  icon,
  rotate,
  size,
  flipH,
  flipV,
  className,
  ...restOfProps
}) => {
  // Look for prefix in icon. Don't prefix it if it's already prefixed
  const iconValue = useMemo(
    () => (icon.match(/^icofont-/) ? icon : `icofont-${icon}`),
    [icon]
  );

  // Manage Rotate Value
  const totalRotate = useMemo(
    () => (rotate ? ` icofont-rotate-${rotate}` : ""),
    [rotate]
  );

  // Manage Size value
  const totalSize = useMemo(() => (size ? ` icofont-${size}x` : ""), [size]);
  const totalflipH = useMemo(
    () => (flipH ? ` icofont-flip-horizontal` : ""),
    [flipH]
  );
  const totalflipV = useMemo(
    () => (flipV ? ` icofont-flip-vertical` : ""),
    [flipV]
  );

  // Handle ClassName
  const cName = useMemo(() => (className ? `${className} ` : ""), [className]);
  // Manage Spin value

  // Render
  return (
    <i
      {...restOfProps}
      className={`${cName}${iconValue}${totalRotate}${totalSize}${totalflipH}${totalflipV}`}
    ></i>
  );
};

Icofont.propTypes = {
  icon: PropTypes.string.isRequired,
  rotate: PropTypes.oneOf([90, 180, 270]),
  size: PropTypes.oneOf(_.range(1, 10)),
  flipH: PropTypes.bool,
  flipV: PropTypes.bool,
  className: PropTypes.string,
};

export default Icofont;
