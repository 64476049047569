import React, { useState } from "react";
import { useSessionStorage } from "../hooks";

const LocationsContext = React.createContext({});

const LocationsContextProvider = ({ children }) => {
  const [locations, setLocations] = useSessionStorage("locations", []);
  const [selectedLocation, setSelectedLocation] = useSessionStorage(
    "selectedLocation",
    {}
  );

  const [isOpenExternalDeliveryModal, setIsOpenExternalDeliveryModal] =
    useState(false);
  const [isOpenMultiLocationModal, setIsOpenMultiLocationModal] =
    useState(false);

  return (
    <LocationsContext.Provider
      value={{
        locations,
        setLocations,
        selectedLocation,
        setSelectedLocation,
        isOpenExternalDeliveryModal,
        setIsOpenExternalDeliveryModal,
        isOpenMultiLocationModal,
        setIsOpenMultiLocationModal,
      }}
    >
      {children}
    </LocationsContext.Provider>
  );
};
export default LocationsContext;
export { LocationsContextProvider, LocationsContext };
