// NOT USED YET
import { useContext, useMemo } from "react";
import { CartContext, LocationsContext } from "../context";
import _ from "lodash";
import usePostalCodePattern from "./usePostalCodePattern";

const useUpdateLocationBasedOnPostalCode = () => {
  const { locations, selectedLocation, setSelectedLocation } =
    useContext(LocationsContext);
  const { shippingAddress } = useContext(CartContext);
  const postalCodePattern = usePostalCodePattern({
    country: selectedLocation?.country,
  });

  const deliveryFees = useMemo(() => {
    if (_.isEmpty(locations)) return {};
    if (!_.isArray(locations)) return {};

    const deliveryOptionsJson = locations.reduce((r, l) => {
      r = { ...r, ...l.deliveryOptionsJson };
      return r;
    }, {});
    if (_.isEmpty(deliveryOptionsJson)) return {};

    return deliveryOptionsJson;
  }, [locations]);

  const postalCodeInfo = () => {
    if (
      _.isEmpty(deliveryFees) ||
      _.isEmpty(shippingAddress) ||
      _.isEmpty(postalCodePattern?.pattern)
    )
      return;

    if (_.isEmpty(shippingAddress?.postalCode)) return;
    const testPattern = new RegExp(postalCodePattern.pattern).test(
      shippingAddress.postalCode
    );
    if (!testPattern) return;

    const checkByCountry =
      checkPostalCode[_.lowerCase(shippingAddress.country)];

    if (!_.isFunction(checkByCountry)) return;

    return checkByCountry(deliveryFees, shippingAddress.postalCode);
  };

  const updateSelectedLocation = (info) => {
    if (locations?.length < 2) return;
    let _info = info;
    if (!_info) _info = postalCodeInfo();

    if (_.isEmpty(_info) || !_.isObject(_info)) return;

    if (_info.id === _.toInteger(selectedLocation.id)) return;

    const res = locations.find((l) => _.toInteger(l.id) === _info.id);
    setSelectedLocation(res);
  };

  return updateSelectedLocation;
};

const checkPostalCode = {
  canada: (deliveryFees, postalCode) => {
    const pc = _.trim(postalCode.toUpperCase());
    const threeDigite = pc.slice(0, 3);
    const formattedPC = `${threeDigite} ${pc.slice(-3)}`;
    const feeWithFull = deliveryFees[formattedPC];
    if (feeWithFull) return feeWithFull;

    const feeWithThreeDigite = deliveryFees[threeDigite];
    if (feeWithThreeDigite) return feeWithThreeDigite;

    return deliveryFees.other;
  },
  denmark: (deliveryFees, postalCode) => {
    const pc = _.trim(postalCode);
    const towDigite = pc.slice(0, 2);
    const formattedPC = `${towDigite}${pc.slice(-2)}`;
    const feeWithFull = deliveryFees[formattedPC];
    if (feeWithFull) return feeWithFull;

    const feeWithTowDigite = deliveryFees[towDigite];
    if (feeWithTowDigite) return feeWithTowDigite;

    return deliveryFees.other;
  },
};
export default useUpdateLocationBasedOnPostalCode;
