import React from "react";
import ContextProviders from "../context";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "../components/AlertTemplate";
import { BreakpointContextProvider } from "../context/breakpointContext";

const alertOptions = {
  // you can also just use 'bottom center'
  position: positions.MIDDLE,
  timeout: 5000,
  offset: "30px",
  // you can also just use 'scale'
  transition: transitions.SCALE,
};

const queries = {
  sm: "(max-width: 576px)",
  md: "(max-width: 768px)",
  lg: "(max-width: 992px)",
  xl: "(max-width: 1200px)",
  or: "(orientation: portrait)", // we can check orientation also
};
const Providers = ({ children }) => {
  return (
    <AlertProvider template={AlertTemplate} {...alertOptions}>
      <ContextProviders>
        <BreakpointContextProvider queries={queries}>
          {children}
        </BreakpointContextProvider>
      </ContextProviders>
    </AlertProvider>
  );
};

export default Providers;
