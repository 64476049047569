import { useContext, useMemo } from "react";
import { LocationsContext } from "../context";
import moment from "moment";
import _ from "lodash";

const useBusinessIsOpen = ({ hoursType = "businessHours" } = {}) => {
  const { selectedLocation } = useContext(LocationsContext);

  const getLoationDate = (time, location) => {
    if (_.isEmpty(location)) return;
    if (_.isEmpty(location[hoursType]?.days)) return;
    const day = _.lowerCase(time.format("dddd"));
    const dayHours = _.find(location[hoursType].days, (d) => d.day === day);
    if (
      _.isEmpty(dayHours) ||
      _.isEmpty(dayHours.openAt) ||
      _.isEmpty(dayHours.closeAt)
    )
      return {
        day,
        time,
        dayHours: { day },
        openTime: undefined,
        closeTime: undefined,
      };

    const [oh, om, os] = _.split(dayHours.openAt, ":");
    const openTime = moment(time).hours(oh).minute(om).second(os);
    const [ch, cm, cs] = _.split(dayHours.closeAt, ":");
    let closeTime = moment(time).hours(ch).minute(cm).second(cs);
    closeTime = ch < oh ? closeTime.add(1, "days") : closeTime;
    return { day, time, dayHours, openTime, closeTime };
  };

  const today = useMemo(() => {
    const time = moment();
    return getLoationDate(time, selectedLocation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation, hoursType]);

  const previousOpenDay = useMemo(() => {
    const getDay = (i) => {
      const time = moment().subtract(i, "d");
      const res = getLoationDate(time, selectedLocation);
      if (res && !res.closeTime) return getDay(i + 1);
      return res;
    };

    return getDay(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation, hoursType]);

  const nextOpenDay = useMemo(() => {
    const getDay = (i) => {
      const time = moment().add(i, "d");
      const res = getLoationDate(time, selectedLocation);
      if (res && !res.closeTime) return getDay(i + 1);
      return res;
    };

    return getDay(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation, hoursType]);

  const dateNow = useMemo(() => {
    if (
      _.isEmpty(today) ||
      _.isEmpty(previousOpenDay) ||
      _.isEmpty(nextOpenDay)
    )
      return;

    if (previousOpenDay.closeTime > today.time) return previousOpenDay;

    if (!today.closeTime || today.closeTime < today.time) return nextOpenDay;

    return today;
  }, [today, previousOpenDay, nextOpenDay]);

  const nextDate = useMemo(() => {
    if (
      _.isEmpty(today) ||
      _.isEmpty(nextOpenDay) ||
      _.isEmpty(previousOpenDay)
    )
      return;

    if (previousOpenDay.closeTime > today.time) return today;

    return nextOpenDay;
  }, [today, nextOpenDay, previousOpenDay]);

  const isOpen = useMemo(() => {
    if (_.isEmpty(dateNow)) return false;
    if (_.isEmpty(selectedLocation)) return false;
    if (_.isEmpty(dateNow.dayHours)) return false;
    const now = moment();

    return now > dateNow.openTime && dateNow.time < dateNow.closeTime;
  }, [dateNow, selectedLocation]);

  const formatTime = (time) => {
    if (_.isEmpty(time)) return;
    const [h, m, s] = _.split(time, ":");
    return moment().hours(h).minute(m).second(s).format("h:mm a");
  };

  const isCloseNextDay = (openAt, closeAt) => {
    // eslint-disable-next-line no-unused-vars
    const [oh, om, os] = _.split(openAt, ":");
    // eslint-disable-next-line no-unused-vars
    const [ch, cm, cs] = _.split(closeAt, ":");
    return ch < oh;
  };

  return {
    formatTime,
    isOpen,
    dateNow,
    nextDate,
    today,
    previousOpenDay,
    nextOpenDay,
    isCloseNextDay,
  };
};

export default useBusinessIsOpen;
